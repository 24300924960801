<template>
  <div class="modal text-black" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Login</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="username" class="form-label">Benutzername</label>
            <input
              type="text"
              name="username"
              id="username"
              class="form-control"
              :class="{ 'is-invalid': error }"
              v-model="username"
            />
            <!--<div class="invalid-feedback my-0">
              {{ error }}
            </div>-->
          </div>
          <div class="form-group">
            <label for="password" class="form-label">Passwort</label>
            <input
              type="password"
              name="password"
              id="password"
              class="form-control"
              :class="{ 'is-invalid': error }"
              v-model="password"
            />
          </div>
          <div
            class="alert alert-danger mt-2 p-1 fade show"
            role="alert"
            v-show="error"
          >
            {{ error }}
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Abbrechen
          </button>
          <button type="button" class="btn btn-primary" @click="login">
            Anmelden
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginModal",
  data() {
    return {
      username: "",
      password: "",
      error: "",
    };
  },
  methods: {
    login() {
      this.error = "";
      const { username, password } = this;
      this.axios
        .post("/api/login", {
          username: username,
          password: password,
        })
        .then(({ data }) => {
          this.$store.commit("setUser", data);
          this.$refs.close.click();
          this.$emit("close");
        })
        .catch((err) => {
          this.error = err.response.data;
        });
    },
  },
};
</script>
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlus,
  faMinus,
  faArrowLeft,
  faArrowRight,
  faEdit,
  faTrash,
  faSpinner,
  faSortDown,
  faSortUp,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// Libraries
import "animate.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import axios from "axios";
import VueAxios from "vue-axios";

// Create app
const app = createApp(App);

// Include all needed libraries
app.use(router);
app.use(store);
app.use(VueAxios, axios);

// Fetch user data again if user is logged in
if (store.state.user) {
  store.dispatch("fetchUser");
}

// Load Fontawesome Icons
library.add(
  faPlus,
  faMinus,
  faArrowLeft,
  faArrowRight,
  faEdit,
  faTrash,
  faSpinner,
  faSortDown,
  faSortUp,
  faSort
);
app.component("font-awesome-icon", FontAwesomeIcon);

// Mount app
app.mount("#app");

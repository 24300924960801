<template>
  <aside>
    <div
      class="filter-group"
      v-for="(filterGroup, groupName) in filters"
      :key="groupName"
    >
      <a
        :id="'filtergroup-button-' + groupName"
        role="button"
        data-bs-toggle="collapse"
        :data-bs-target="'#filtergroup-' + groupName"
        @click="updateGroupIcon(filterGroup, $event)"
      >
        <b>{{ groupName }}</b>
        <font-awesome-icon class="filtergroup-icon" :icon="filterGroup.icon" />
      </a>

      <div class="collapse" :id="'filtergroup-' + groupName">
        <div>
          <label
            class="filterOption"
            v-for="(filterOptions, filterName) in filterGroup.filters"
            :key="filterGroup + '.' + filterName"
            :for="'filter-' + filterName"
          >
            <input
              type="checkbox"
              :name="'filter-' + filterName"
              :id="'filter-' + filterName"
              v-model="filterOptions.checked"
              @change="onFiltersChange()"
            />
            {{ filterName }}

            <span>({{ filterOptions.rows }})</span>
          </label>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      filters: {
        Standort: {
          icon: "plus",
          filters: {
            "nicht zugeordnet": {
              checked: false,
              rows: 5,
            },
            AVOR: {
              checked: false,
              rows: 5,
            },
            Layout: {
              checked: true,
              rows: 5,
            },
            GzA: {
              checked: false,
              rows: 5,
            },
            Plattenherstellung: {
              checked: false,
              rows: 5,
            },
            Print: {
              checked: false,
              rows: 5,
            },
            Finishing: {
              checked: false,
              rows: 5,
            },
            VMI: {
              checked: false,
              rows: 5,
            },
            Fremdarbeit: {
              checked: false,
              rows: 5,
            },
            abgeliefert: {
              checked: false,
              rows: 5,
            },
          },
        },
        Produktion: {
          icon: "plus",
          filters: {
            "nicht zugeordnet": {
              checked: false,
              rows: 5,
            },
            AVOR: {
              checked: false,
              rows: 5,
            },
            Layout: {
              checked: true,
              rows: 5,
            },
            GzA: {
              checked: false,
              rows: 5,
            },
            Plattenherstellung: {
              checked: false,
              rows: 5,
            },
            Print: {
              checked: false,
              rows: 5,
            },
            Finishing: {
              checked: false,
              rows: 5,
            },
            VMI: {
              checked: false,
              rows: 5,
            },
            Fremdarbeit: {
              checked: false,
              rows: 5,
            },
            abgeliefert: {
              checked: false,
              rows: 5,
            },
          },
        },
        "Termin-Anzeige": {
          icon: "plus",
          filters: {
            "nicht zugeordnet": {
              checked: false,
              rows: 5,
            },
            AVOR: {
              checked: false,
              rows: 5,
            },
            Layout: {
              checked: true,
              rows: 5,
            },
            GzA: {
              checked: false,
              rows: 5,
            },
            Plattenherstellung: {
              checked: false,
              rows: 5,
            },
            Print: {
              checked: false,
              rows: 5,
            },
            Finishing: {
              checked: false,
              rows: 5,
            },
            VMI: {
              checked: false,
              rows: 5,
            },
            Fremdarbeit: {
              checked: false,
              rows: 5,
            },
            abgeliefert: {
              checked: false,
              rows: 5,
            },
          },
        },
        Spalten: {
          icon: "plus",
          filters: {
            "nicht zugeordnet": {
              checked: false,
              rows: 5,
            },
            AVOR: {
              checked: false,
              rows: 5,
            },
            Layout: {
              checked: true,
              rows: 5,
            },
            GzA: {
              checked: false,
              rows: 5,
            },
            Plattenherstellung: {
              checked: false,
              rows: 5,
            },
            Print: {
              checked: false,
              rows: 5,
            },
            Finishing: {
              checked: false,
              rows: 5,
            },
            VMI: {
              checked: false,
              rows: 5,
            },
            Fremdarbeit: {
              checked: false,
              rows: 5,
            },
            abgeliefert: {
              checked: false,
              rows: 5,
            },
          },
        },
      },
    };
  },
  methods: {
    onFiltersChange() {
      console.log(this.filters);
    },
    updateGroupIcon(group, e) {
      const link = e.target.closest("a");

      group.icon = link.classList.contains("collapsed") ? "plus" : "minus";
    },
  },
};
</script>

<style scoped>
aside {
  background-color: #f5f5f5;
  float: left;
  width: min-content;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  padding: 2rem;
  
  /* +2rem because footer is 2rem big */
  padding-bottom: 4rem;
}

.filter-group {
  display: flex;
  flex-direction: column;
  background: none;
}

.filter-group > a {
  width: 12rem;
  margin-top: 0.5rem;
}

.filterOption {
  position: relative;
}

.filterOption > span {
  position: absolute;
  right: 0;
  width: 2rem;
  text-align: right;
}

.filtergroup-icon {
  float: right;
  vertical-align: middle;
  transform: translateY(25%);
}

.filter-group > div > div {
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  position: relative;
  font-size: 9pt;
}
</style>
<template>
  <div class="main">
    <div>
      <div
        class="triangle-container"
        @click="toggleFullscreen"
        v-show="!hideFullscreenIcon"
      >
        <div class="triangle" :class="{ full: $store.state.fullscreen }"></div>
        <font-awesome-icon
          :icon="$store.state.fullscreen ? 'arrow-right' : 'arrow-left'"
        ></font-awesome-icon>
      </div>
      <h2>
        <router-link :to="{ name: 'Home' }">Termin-Datenbank</router-link>
      </h2>
    </div>
    <div class="text-white">
      <div v-if="$store.state.user">
        <!-- User is logged in -->
        <!-- Display username and make a dropdown -->
        <div class="dropdown">
          <button
            class="btn text-white dropdown-toggle outline-none box-shadow-none"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ $store.state.user.user_username }}
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <!-- User Administration -->
            <router-link
              v-if="$store.state.user.user_userGroup_id === 1"
              :to="{ name: 'Users' }"
              class="dropdown-item"
            >
              Benutzerverwaltung
            </router-link>

            <!-- User Group Administration -->
            <router-link
              v-if="$store.state.user.user_userGroup_id === 1"
              :to="{ name: 'UserGroups' }"
              class="dropdown-item"
            >
              Benutzergruppenverwaltung
            </router-link>

            <!-- Route to home -->
            <router-link class="dropdown-item" :to="{ name: 'Home' }">
              Home
            </router-link>

            <!-- Logout button -->
            <a href="#" class="dropdown-item" @click.prevent="logout">
              Abmelden
            </a>
          </div>
        </div>
      </div>
      <div v-else>
        <!-- User is not logged in -->
        <a
          href="#"
          data-bs-toggle="modal"
          data-bs-target="#LoginModal"
          class="login-link"
          >Login</a
        >

        <LoginModal id="LoginModal" class="animate__animated animate__fadeIn" />
      </div>
    </div>
  </div>
</template>

<script>
import LoginModal from "@/components/modals/LoginModal.vue";

export default {
  name: "Header",
  components: { LoginModal },
  props: {
    hideFullscreenIcon: Boolean,
  },
  created() {
    console.log(this.$props);
  },
  methods: {
    toggleFullscreen() {
      this.$store.commit("toggleFullscreen");
    },
    logout() {
      this.axios.post("/api/logout").then(() => {
        this.$store.commit("setUser", undefined);
      });
    },
  },
};
</script>

<style scoped>
.main {
  background: #4d4d4d;
  height: 3rem;
  display: flex;
  justify-content: space-between;
  line-height: 2rem;
}
.main > div > h2,
.main > div > h2 * {
  color: #f8f8f8;
  font-size: 2rem;
  text-decoration: none;
}

.main > div {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.triangle-container {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  justify-content: center;
  position: relative;
  margin-right: 1rem;
}

.triangle {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 1.5rem solid transparent;
  border-right: 1.5rem solid transparent;
  border-top: 1.5rem solid #d8d8d8;
  border-left: 1.5rem solid #d8d8d8;
}

.triangle-container svg {
  position: absolute;
  width: 50%;
  height: 50%;
  top: 0.1rem;
  left: 0.15rem;
  background: none;
  transform: rotateZ(45deg);
  color: #4d4d4d;
}

.login-link {
  text-decoration: none;
  color: white !important;
}
</style>
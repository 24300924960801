import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Users from "../views/administration/Users.vue";
import EditUser from "../views/administration/EditUser.vue";
import CreateUser from "../views/administration/CreateUser.vue";
import UserGroups from "../views/administration/UserGroups.vue";
import EditUserGroup from "../views/administration/EditUserGroup.vue";
import CreateUserGroup from "../views/administration/CreateUserGroup.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      authRequired: true,
      adminRequired: true,
    },
  },
  {
    path: "/users/:id/edit",
    name: "EditUser",
    component: EditUser,
    meta: {
      authRequired: true,
      adminRequired: true,
    },
  },
  {
    path: "/users/create",
    name: "CreateUser",
    component: CreateUser,
    meta: {
      authRequired: true,
      adminRequired: true,
    },
  },
  {
    path: "/usergroups",
    name: "UserGroups",
    component: UserGroups,
    meta: {
      authRequired: true,
      adminRequired: true,
    },
  },
  {
    path: "/usergroups/:id/edit",
    name: "EditUserGroup",
    component: EditUserGroup,
    meta: {
      authRequired: true,
      adminRequired: true,
    },
  },
  {
    path: "/usergroups/create",
    name: "CreateUserGroup",
    component: CreateUserGroup,
    meta: {
      authRequired: true,
      adminRequired: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Add a beforeEach guard to the router
router.beforeEach((to, from, next) => {
  // If the user is not authenticated and tries to access a restricted page,
  // redirect to the login page
  const authRequired = to.matched.some((record) => record.meta.authRequired);

  // If we dont need to check for authentication, just continue
  if (!authRequired) {
    return next();
  }

  const loggedIn = store.state.user !== undefined;
  if (!loggedIn) {
    return next("/");
  }

  // If the user is authenticated and tries to access an admin page,
  // redirect to the home page
  const adminRequired = to.matched.some((record) => record.meta.adminRequired);
  const isAdmin = store.state.user.user_userGroup_id === 1;

  if (adminRequired && !isAdmin) {
    return next("/");
  }

  next();
});

export default router;

<template>
  <div>
    <Sidebar v-show="!$store.state.fullscreen"></Sidebar>
    <main class="w-100" :class="{ fullscreen: $store.state.fullscreen }">
      <Header></Header>
    </main>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Sidebar from "@/components/Sidebar.vue";

export default {
  name: "Home",
  components: {
    Header,
    Sidebar,
    Footer,
  },
};
</script>

<style scoped>
</style>

<template>
  <router-view id="router-view" />
</template>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  position: relative;
  font-size: 11pt;
  color: #212529;
  text-align: left;
  background-color: #fff;
  min-height: 100vh;
}

.fullscreen {
  height: 100vh;
  min-width: 100vw;
  margin: 0;
  padding: 0;
}

#router-view {
  display: flex;
  min-height: 100vh;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

.outline-none,
.outline-none:focus,
.outline-none:hover {
  outline: none !important;
}

.box-shadow-none,
.box-shadow-none:focus,
.box-shadow-none:hover {
  box-shadow: none !important;
}
</style>

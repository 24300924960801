import VuexPersistence from "vuex-persist";
import { createStore } from "vuex";
import axios from "axios";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default createStore({
  state: {
    fullscreen: false,
    user: undefined,
  },
  mutations: {
    toggleFullscreen(state) {
      state.fullscreen = !state.fullscreen;
    },
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    fetchUser({ commit }) {
      axios
        .get("/api/login")
        .then((response) => {
          console.log(response.data);
          commit("setUser", response.data);
        })
        .catch(() => {
          // User is not logged in
          console.log("User is not logged in");
          commit("setUser", undefined);
        });
    },
  },
  modules: {},
  plugins: [vuexLocal.plugin],
});

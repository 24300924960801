<template>
  <div>
    <Sidebar v-show="!fullscreen"></Sidebar>
    <main class="w-100" :class="{ fullscreen: fullscreen }">
      <Header></Header>
    </main>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Sidebar from "@/components/Sidebar.vue";

export default {
  name: "About",
  data() {
    return {
      fullscreen: false,
    };
  },
  components: {
    Header,
    Sidebar,
  },
};
</script>

<style scoped>
.fullscreen {
  height: 100vh;
  min-width: 100vw;
  margin: 0;
  padding: 0;
}
</style>

<template>
  <div>
    <main class="w-100 fullscreen">
      <Header hide-fullscreen-icon></Header>

      <div class="">
        <h1 class="text-center my-2">Benutzerverwaltung</h1>
        <div
          class="w-75 mx-auto table-container"
          :class="{ 'is-loading': tableData.isLoading }"
        >
          <div class="loading-overlay">
            <div class="loading-overlay__spinner">
              <font-awesome-icon class="fa-spin fa-xl" icon="spinner" />
            </div>

            <div class="loading-overlay__text">
              <span class="loading-overlay__text--small">Lade Daten...</span>
            </div>
          </div>

          <div class="w-100 d-flex header-navigation">
            <div
              class="w-50 h-100 d-flex justify-content-start align-items-center"
            >
              <!-- Page size select -->
              <label for="pageSize">Pro Seite: </label>
              <select
                id="pageSize"
                class="form-select w-25 ms-2"
                v-model="tableData.pageSize"
                @change="fetchUsers()"
              >
                <option value="1">1</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
              </select>
            </div>
            <div
              class="w-50 h-100 d-flex justify-content-end align-items-center"
            >
              <!-- Search input -->
              <label for="search" v-if="searchTimeout != null">
                <font-awesome-icon class="fa-spin fa-xl" icon="spinner" />
              </label>
              <label for="search" v-else>Suche: </label>
              <input
                id="search"
                class="form-control w-25 mx-2"
                v-model="tableData.search"
                @keyup="limitFetchUsers($event)"
              />

              <!-- Add button -->
              <router-link class="btn btn-success" :to="{ name: 'CreateUser' }">
                <font-awesome-icon icon="plus" /> Benutzer hinzufügen
              </router-link>
            </div>
          </div>

          <table
            class="
              table table-striped table-bordered
              border-dark
              user-table
              mt-3
            "
          >
            <thead class="table-secondary border-dark">
              <tr>
                <th @click="sortBy('user_uid')">
                  <span v-if="tableData.order == 'user_uid'">
                    <font-awesome-icon
                      :icon="
                        tableData.orderDirection == 'ASC'
                          ? 'sort-up'
                          : 'sort-down'
                      "
                    />
                  </span>
                  <span v-else>
                    <font-awesome-icon icon="sort" class="text-muted" />
                  </span>
                  ID
                </th>
                <th @click="sortBy('usergroup_name')">
                  <span v-if="tableData.order == 'usergroup_name'">
                    <font-awesome-icon
                      :icon="
                        tableData.orderDirection == 'ASC'
                          ? 'sort-up'
                          : 'sort-down'
                      "
                    />
                  </span>
                  <span v-else>
                    <font-awesome-icon icon="sort" class="text-muted" /> </span
                  >Benutzergruppe
                </th>
                <th @click="sortBy('user_username')">
                  <span v-if="tableData.order == 'user_username'">
                    <font-awesome-icon
                      :icon="
                        tableData.orderDirection == 'ASC'
                          ? 'sort-up'
                          : 'sort-down'
                      "
                    />
                  </span>
                  <span v-else>
                    <font-awesome-icon icon="sort" class="text-muted" /> </span
                  >Benutzername
                </th>
                <th @click="sortBy('user_email')">
                  <span v-if="tableData.order == 'user_email'">
                    <font-awesome-icon
                      :icon="
                        tableData.orderDirection == 'ASC'
                          ? 'sort-up'
                          : 'sort-down'
                      "
                    />
                  </span>
                  <span v-else>
                    <font-awesome-icon icon="sort" class="text-muted" /> </span
                  >Email
                </th>
                <th @click="sortBy('user_firstName')">
                  <span v-if="tableData.order == 'user_firstName'">
                    <font-awesome-icon
                      :icon="
                        tableData.orderDirection == 'ASC'
                          ? 'sort-up'
                          : 'sort-down'
                      "
                    />
                  </span>
                  <span v-else>
                    <font-awesome-icon icon="sort" class="text-muted" /> </span
                  >Vorname
                </th>
                <th @click="sortBy('user_lastName')">
                  <span v-if="tableData.order == 'user_lastName'">
                    <font-awesome-icon
                      :icon="
                        tableData.orderDirection == 'ASC'
                          ? 'sort-up'
                          : 'sort-down'
                      "
                    />
                  </span>
                  <span v-else>
                    <font-awesome-icon icon="sort" class="text-muted" /> </span
                  >Nachname
                </th>
                <th @click="sortBy('user_isActive')">
                  <span v-if="tableData.order == 'user_isActive'">
                    <font-awesome-icon
                      :icon="
                        tableData.orderDirection == 'ASC'
                          ? 'sort-up'
                          : 'sort-down'
                      "
                    />
                  </span>
                  <span v-else>
                    <font-awesome-icon icon="sort" class="text-muted" /> </span
                  >Aktiv?
                </th>
                <th @click="sortBy('user_timeCreated')">
                  <span v-if="tableData.order == 'user_timeCreated'">
                    <font-awesome-icon
                      :icon="
                        tableData.orderDirection == 'ASC'
                          ? 'sort-up'
                          : 'sort-down'
                      "
                    />
                  </span>
                  <span v-else>
                    <font-awesome-icon icon="sort" class="text-muted" /> </span
                  >Erstelldatum
                </th>
                <th @click="sortBy('user_lastLoginDate')">
                  <span v-if="tableData.order == 'user_lastLoginDate'">
                    <font-awesome-icon
                      :icon="
                        tableData.orderDirection == 'ASC'
                          ? 'sort-up'
                          : 'sort-down'
                      "
                    />
                  </span>
                  <span v-else>
                    <font-awesome-icon icon="sort" class="text-muted" /> </span
                  >Zuletzt eingeloggt
                </th>
                <th class="text-center">Aktionen</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in tableData.rows" :key="user.user_uid">
                <td>{{ user.user_uid }}</td>
                <td>{{ user.usergroup_name }}</td>
                <td>{{ user.user_username }}</td>
                <td>{{ user.user_email }}</td>
                <td>{{ user.user_firstName }}</td>
                <td>{{ user.user_lastName }}</td>
                <td
                  :class="[user.user_isActive ? 'text-success' : 'text-danger']"
                >
                  {{ user.user_isActive ? "Ja" : "Nein" }}
                </td>
                <td>{{ displayDate(user.user_timeCreated) }}</td>
                <td>{{ displayDate(user.user_lastLoginDate) }}</td>
                <td class="text-center">
                  <router-link
                    :to="{
                      name: 'EditUser',
                      params: { id: user.user_uid },
                    }"
                  >
                    <button class="btn btn-warning text-white me-2">
                      <font-awesome-icon icon="edit"></font-awesome-icon>
                    </button>
                  </router-link>
                  <button
                    class="btn btn-danger"
                    @click="deleteUser(user.user_uid)"
                  >
                    <font-awesome-icon icon="trash"></font-awesome-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="d-flex justify-content-between">
            <!-- Current Page Information -->
            <span>
              Eintrag {{ (tableData.page - 1) * tableData.pageSize + 1 }} bis
              {{
                (tableData.page - 1) * tableData.pageSize +
                tableData.rows.length
              }}
              von
              {{ tableData.total }}
              <span v-if="tableData.search">(gefiltert)</span>
            </span>

            <!-- Pagination -->
            <ul class="pagination">
              <!-- Previous Button -->
              <li
                class="page-item"
                :class="{ disabled: tableData.page - 1 <= 0 }"
              >
                <a
                  href="#"
                  class="page-link"
                  :class="{ disabled: tableData.page - 1 <= 0 }"
                  @click.prevent="previousPage"
                  >Vorherige</a
                >
              </li>

              <!-- 1 ...  -->
              <li
                class="page-item"
                v-show="tableData.pageAmount > 3 && tableData.page - 1 > 1"
              >
                <a
                  href="#"
                  class="page-link"
                  @click.prevent="changePage($event)"
                  >1</a
                >
              </li>

              <li
                class="page-item disabled"
                v-show="tableData.pageAmount > 3 && tableData.page - 1 > 1"
              >
                <a
                  href="#"
                  class="page-link disabled"
                  v-if="tableData.page - 1 > 1"
                  >...</a
                >
                <!--
                <a
                  href="#"
                  class="page-link"
                  @click.prevent="changePage($event)"
                  v-if="tableData.page - 1 === 1"
                  >2</a
                >-->
              </li>

              <!-- Pagination Buttons -->
              <li class="page-item" v-if="tableData.page - 1 > 0">
                <a href="#" class="page-link" @click.prevent="previousPage">
                  {{ tableData.page - 1 }}
                </a>
              </li>
              <li class="page-item active">
                <a href="#" class="page-link">
                  {{ tableData.page }}
                </a>
              </li>
              <li
                class="page-item"
                v-if="tableData.page + 1 <= tableData.pageAmount"
              >
                <a href="#" class="page-link" @click.prevent="nextPage">
                  {{ tableData.page + 1 }}
                </a>
              </li>

              <!-- ... 10  -->
              <li
                class="page-item disabled"
                v-show="
                  tableData.pageAmount > 3 &&
                  tableData.page + 1 < tableData.pageAmount
                "
              >
                <a href="#" class="page-link disabled">...</a>
              </li>
              <li
                class="page-item"
                v-show="
                  tableData.pageAmount > 3 &&
                  tableData.page + 1 < tableData.pageAmount
                "
              >
                <a
                  href="#"
                  class="page-link"
                  @click.prevent="changePage($event)"
                  >{{ tableData.pageAmount }}</a
                >
              </li>

              <!-- Next Button -->
              <li
                class="page-item"
                :class="{
                  disabled: tableData.page + 1 > tableData.pageAmount,
                }"
              >
                <a
                  href="#"
                  class="page-link"
                  :class="{
                    disabled: tableData.page + 1 > tableData.pageAmount,
                  }"
                  @click.prevent="nextPage"
                  >Nächste</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Users",
  data() {
    return {
      searchTimeout: null,
      tableData: {
        rows: [],
        page: 1,
        pageSize: 10,
        pageAmount: 1,
        total: 0,
        isLoading: true,
        search: "",
        order: "",
        orderDirection: "ASC",
      },
    };
  },
  methods: {
    async fetchUsers() {
      this.tableData.isLoading = true;
      const response = await this.axios({
        method: "GET",
        url:
          "/api/user?req_page=" +
          this.tableData.page +
          "&req_page_size=" +
          this.tableData.pageSize +
          "&req_search=" +
          this.tableData.search +
          "&req_order=" +
          this.tableData.order +
          "&req_order_direction=" +
          this.tableData.orderDirection,
      });

      this.tableData.rows = response.data.rows;
      this.tableData.total = response.data.total;
      this.tableData.pageAmount = response.data.pageAmount;
      this.tableData.isLoading = false;
    },
    limitFetchUsers(event) {
      // If the key is enter, do the search
      if (event.keyCode === 13) {
        clearTimeout(this.searchTimeout);
        this.fetchUsers();
        return;
      }

      // If the key is not enter, wait for the user to stop typing
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.fetchUsers();
        this.searchTimeout = null;
      }, 1000);
    },
    async deleteUser(id) {
      const response = await this.axios({
        method: "DELETE",
        url: `/api/user/${id}`,
      });
      console.log(response);
    },
    async nextPage() {
      if (this.tableData.page < this.tableData.pageAmount) {
        this.tableData.page++;
        this.fetchUsers();
      }
    },
    async previousPage() {
      if (this.tableData.page > 1) {
        this.tableData.page--;
        this.fetchUsers();
      }
    },
    async changePage(event) {
      this.tableData.page = parseInt(event.target.text);
      this.fetchUsers();
    },
    displayDate(date) {
      var t = date.split(/[- :]/);
      console.log(t);
      var jsDate = new Date(
        Date.UTC(t[0], t[1] - 1, t[2], t[3] - 2, t[4], t[5])
      );

      return jsDate.toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        second: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    sortBy(column) {
      if (this.tableData.order === column) {
        // Flip order direction from ASC to DESC
        this.tableData.orderDirection =
          this.tableData.orderDirection === "ASC" ? "DESC" : "ASC";
      } else {
        // Set order direction to ASC
        this.tableData.orderDirection = "ASC";

        // Set order to the column
        this.tableData.order = column;
      }
      this.fetchUsers();
    },
  },
  mounted() {
    this.fetchUsers();
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
.user-table thead th:nth-child(1),
.user-table tbody tr td:nth-child(1) {
  width: 1% !important;
}

.table-container {
  position: relative;
}

.table-container.is-loading,
.table-container.is-loading * {
  pointer-events: none;
}

/* Hide the loading overlay when the table is not loading */
.table-container:not(.is-loading) .loading-overlay {
  display: none;
}

/* Make a loading overlay when the table has the class is-loading */
.table-container.is-loading .loading-overlay {
  position: absolute;
  top: -2.5%;
  left: -2.5%;
  width: 105%;
  height: 105%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  /* Horizontally and Vertically center text */
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  flex-direction: column;
}

.header-navigation {
  height: 3rem;
}

.user-table tr td:last-child {
  min-width: 7rem;
}
</style>
<template>
  <footer>
    <span class="w-50 ms-3">© 2022 Paul Büetiger AG | SWISS DESIGNED & DEVELOPED</span>
    <span class="w-50 text-end me-3">Tel. +41 32 671 23 23 | info@buetiger.ch</span>

  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
footer {
  position: fixed;
  display: flex;
  bottom: 0;
  height: 2rem;
  width: 100%;
  background-color: #4d4d4d;
  color: #fff;
  font-size: 9pt;
  line-height: 2rem;
}
</style>